.crumbs-ctn {
  margin-top: 5px;
  span {
    cursor: pointer;
    font-size: 14px;
    font-family: var(--inter);
    font-style: normal;
    font-weight: 600;
    line-height: 23px;
    color: #7a7a9d;
  }
  .b-txt {
    color: #27272e;
  }
  img {
    margin: 0px 5px 2px 5px;
  }
}
