.see-all {
  width: 40%;
  font-family: var(--inter);
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  color: #4c6fff;
  text-align: end;
  cursor: pointer;
}
