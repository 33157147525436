.daterange{
  .s-label {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 13px;
    color: #16192c;
  }
}

.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(
    .ant-picker-cell-disabled
  )
  .ant-picker-cell-inner {
  background-color: #FFE6E4;
  color: #FA1C3C;
  font-weight: 600;
}

.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(
    .ant-picker-cell-disabled
  )
  .ant-picker-cell-inner {
  background-color: #FFE6E4;
  color: #FA1C3C;
  font-weight: 600;
}

.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-today
  .ant-picker-cell-inner::before {
  border-color: #FA1C3C;
}

.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-today{
    color: #FA1C3C;
    font-weight: 600;
  }

.ant-picker-range .ant-picker-active-bar {
  background-color: #FA1C3C;
}

.ant-picker-prev-icon,
.ant-picker-next-icon {
  color: #FA1C3C;
}

.ant-picker-super-prev-icon,
.ant-picker-super-next-icon {
  color: #FA1C3C;
}

.ant-picker .ant-picker-input >input{
  line-height: 2.3990015714285714;
}

@media(max-width: 780px) { 
  .ant-picker-panels { 
    flex-direction: column;
    width: 50%;
  } 
  .ant-picker .ant-picker-input >input{
    width: 4.5rem !important;
  }
}
