.integration-keys {
  .top-br {
    width: 100%;
  }
  .inner-ctn {
    padding: 10px 30px 120px 30px;
    margin-top: 104px;
  }

  .empty-key-ctn {
    padding: 200px 0;
    max-width: 200px;
    margin: auto;

    text-align: center;
  }

  .r-form {
    margin-top: 26px;
    .form-group {
      .inner-form {
        .eye-icon-img {
          bottom: 15px;
          right: 14px;
        }
      }
    }
  }

  .key-boxes {
    gap: 30px !important;
  }
}

@media screen and (max-width: 992px) {
  .key-boxes {
    flex-wrap: nowrap;
  }
}

@media screen and (max-width: 670px) {
  .integration-keys {
    .key-boxes {
      flex-wrap: wrap;
      gap: 10px !important;
    }

    .inner-ctn {
      padding: 10px 16px 91px 16px;
    }
  }
  .top-br {
    button {
      width: 170px !important;
    }
  }
}

@media screen and (max-width: 467px) {
  .key-boxes {
    gap: 16px !important;
  }
}
