.subscribers-list {
  position: relative;
  display: flex;
  align-items: center;
  // margin-bottom: 30px;

  img,
  .character-circle {
    position: absolute;
    top: -18px;

    border-radius: 50%;
    width: 36px;
    height: 36px;
  }

  .am-div {
    @extend img;
    background-color: var(--secondary);
    left: 68px;

    font-style: normal;
    font-weight: 700;
    font-size: 11px;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
