.account-container {
  .inner-ctn {
    padding: 10px 30px 120px 30px;
    background-color: var(--bg);
    margin-top: 104px;
  }

  .r-form {
    display: flex;
    justify-content: space-between;

    .form-group {
      width: 31%;
    }
  }

  .save-changes-btn {
    padding-top: 60px;
    max-width: 152px;
    margin: auto;
  }
}

@media only screen and (max-width: 767px) {
  .account-container {
    .r-form {
      flex-wrap: wrap;

      .form-group {
        width: 49%;
      }
    }
  }
}

@media only screen and (max-width: 467px) {
  .account-container {
    .uploader-box {
      flex-wrap: wrap;
      justify-content: center;
    }
    .r-form {
      flex-wrap: wrap;

      .form-group {
        width: 99%;
      }
    }

    .save-changes-btn {
      padding-top: 10px;
    }
  }
}
