.key-box {
  width: 100%;
  .sixty-twelve {
    color: #7a7a9d;
  }
  .date {
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 136.523%;
    color: #27272e;
  }
}

@media screen and (max-width: 970px) {
  .key-box {
    .btn-style {
      width: 82px !important;
      font-size: 12px !important;
      padding: 12px 10px;
    }
  }
}
