.uploader-box {
  margin-top: 28px;
  box-shadow: 0px 3px 8px -1px rgba(50, 50, 71, 0.05),
    0px 0px 1px 0px rgba(12, 26, 75, 0.24);
  padding: 24px 18px;
  background-color: #ffffff;
  border-radius: 10px;

  flex-wrap: nowrap !important;
  .mediumUpper {
    text-align: left;
  }
  .p-img {
    width: 48px;
    height: 48px;

    border-radius: 50%;
  }

  .note {
    font-family: var(--inter);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 23px;
    color: #425466;
    text-align: left;
  }
  .up-img {
    cursor: pointer;
  }
}

@media only screen and (max-width: 767px) {
  .uploader-box {
    .p-img {
      margin-right: 10px;
    }
    .note {
      font-size: 12px;

      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
