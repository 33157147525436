.reward-access {
  .inner-ctn {
    padding: 30px 30px 120px 30px;
    background-color: var(--bg);
    margin-top: 104px;
  }

  table {
    tr {
      th {
        width: 40% !important;
      }
    }
  }
}
