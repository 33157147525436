.agency-dashboard {
  .agency-barContent {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .inner-ctn {
    padding: 30px 30px 120px 30px;
    background-color: var(--bg);
    margin-top: 104px;

    .subs-txt {
      font-family: var(--inter);
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      color: #7a7a9d;

      span {
        background: #ff92ae;
        width: 8px;
        height: 8px;
        border-radius: 50%;
      }
    }

    .goldie-row-tc > .col-lg-3:nth-child(9),
    .goldie-row-tc > .col-lg-3:nth-child(10) {
      width: 50% !important;
    }
  }

  .goldie-col {
    margin-top: 32px;
  }

  .shadow-unset {
    box-shadow: unset !important;
    filter: none !important;
  }

  .world-map-bx {
    height: 483px;
  }
}

@media only screen and (min-width: 2000px) {
  .agency-dashboard {
    .world-map-bx {
      height: auto;
    }
  }
}

@media only screen and (max-width: 1430px) {
  .agency-dashboard {
    .on-board-colAg,
    .world-map-col {
      width: 50% !important;
    }
  }
}

@media screen and (max-width: 1350px) {
  .agency-dashboard {
    .c-col {
      padding: 5px !important;
      width: 33% !important;
      flex: unset;

      .box-hoc {
        padding: 15px !important;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .agency-dashboard {
    .c-col {
      width: 49% !important;
    }
  }
}

@media only screen and (max-width: 1124px) {
  .agency-dashboard {
    .on-board-colAg,
    .world-map-col {
      width: 49% !important;
      min-height: 382px !important;
    }

    .world-map-bx {
      height: 476px !important;
      min-height: 382px !important;
    }
    .goldie-col {
      width: 24.5% !important;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .agency-dashboard {
    .c-col {
      width: 33% !important;
    }
  }
  .agency-barContent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    flex-wrap: wrap;
    .css-1tc1fc6-control,
    .css-1qpsdfz-control {
      width: 200px !important;
    }
  }
}
@media screen and (max-width: 875px) {
  .agency-dashboard {
    .inner-ctn {
      padding: 25px 18px 80px 18px;
    }
    .c-col {
      width: 49% !important;
      flex: unset;
    }

    .goldie-row-tc {
      .goldie-col {
        margin-top: 16px;
        width: 33% !important;
        flex: unset;
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .agency-dashboard {
    .on-board-colAg,
    .world-map-col {
      width: 100% !important;
    }

    .world-map-bx {
      height: auto !important;
      min-height: 100% !important;
    }

    .c-col {
      padding: 15px 15px 0px 15px !important;
    }
  }
}

@media only screen and (max-width: 610px) {
  .agency-dashboard {
    .c-col {
      width: 100% !important;
      flex: unset;
    }

    .goldie-row-tc {
      .goldie-col {
        width: 49% !important;
        flex: unset;
      }
    }
    .earn-chart {
      margin-top: 20px;
    }

    .inner-ctn {
      .goldie-row-tc > .col-lg-3:nth-child(9),
      .goldie-row-tc > .col-lg-3:nth-child(10) {
        width: 100% !important;
      }
    }
  }
}

@media only screen and (max-width: 410px) {
  .agency-barContent {
    flex-wrap: wrap;
    gap: 10px;
    .css-1tc1fc6-control,
    .css-1qpsdfz-control {
      width: 120px !important;
    }
  }

  .agency-dashboard {
    .goldie-row-tc {
      .goldie-col {
        width: 100% !important;
      }
    }
  }
}
