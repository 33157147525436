.add-media-company {
  .uploader-box {
    .note {
      font-family: var(--inter);
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      color: #425466;
      text-align: left;
    }
  }

  .r-form {
    margin-top: 26px;

    .form-group {
      .inner-form {
        .eye-icon-img {
          bottom: 15px;
          right: 14px;
        }

        .field-error {
          bottom: 36px;
        }
      }
    }
  }

  .mb-3 {
    margin-bottom: 0.5rem !important;
  }

  .r-form label {
    margin-top: 0px !important;
  }

  .terms-condition {
    margin-top: 30px;
    align-items: start;

    .tc-txt {
      font-family: var(--inter);
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      color: #425466;
      text-align: left;

      span {
        font-weight: 600;
        color: #27272e;
      }
    }
  }
}

.withdraw-card {
  margin-top: 1em;
  box-shadow: 0px 3px 8px -1px rgba(50, 50, 71, 0.05), 0px 0px 1px 0px rgba(12, 26, 75, 0.24);
  border-radius: 16px;
  border: none;
}

.for-media-popup .modal-body {
  padding-top: 47px
}

.card-desc {
  color: #7A7A9D
}

@media only screen and (max-width: 467px) {
  .add-media-company {
    .uploader-box {
      flex-wrap: wrap;
      justify-content: center;
    }
  }
}