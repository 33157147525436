.trending-list {
  .inner-ctn {
    padding: 10px 30px 120px 30px;
    background-color: var(--bg);
    margin-top: 104px;
  }

  .for-art-vid {
    border-radius: 10px !important;
    width: 44px !important;
    height: 28px !important;
  }

  .comp-with-subs .default-v-a-pl {
    height: 46px !important;
    width: 46px;
  }
}

@media screen and (max-width: 1024px) {
  .trending-list {
    .inner-ctn {
      padding: 10px 47px 91px 47px;
    }
  }
}

@media screen and (max-width: 700px) {
  .trending-list {
    .inner-ctn {
      padding: 10px 16px 91px 16px;
    }
  }
}
