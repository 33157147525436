.company-box {
  .cmp-bx-row {
    display: flex;
    align-items: center;
    justify-content: space-between;

    gap: 10px;
    margin-bottom: 8px;

    .mediumUpper {
      display: -webkit-box;
      max-height: 25px;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
