.status-bage {
  padding: 7px 10px;
  border-radius: 6px;
  border: none !important;

  font-family: var(--inter);
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 16px;
  max-width: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.2rem;

  text-align: center;

  .tooltip {
    border-radius: 0.4rem;
    background-color: #fcfafa;
    color: #8e99a8;
    font-size: 11px;
    font-weight: 500;
    box-shadow: 0px 1px 2px 0px #3232471A;
    padding: 13px;
    line-height: 16px;
  }
}

.status-bage.active,
.status-bage.article,
.status-bage.transferred,
.status-bage.completed,
.status-bage.approved {
  @extend .status-bage;
  background-color: #deffee;
  color: #66cb9f;
}

.status-bage.inactive,
.status-bage.video,
.status-bage.rejected {
  @extend .status-bage;
  background: #FFE6E4;
  color: #f16063;
}

.status-bage.goldiesWallet {
  @extend .status-bage;
  min-width: 120px;
  background-color: #ebe9ff;
  color: #7d6bff;
}

.status-bage.coming-soon {
  @extend .status-bage;
  min-width: 120px;
  background-color: #ffdde2;
  color: #f91c3b;
}

.status-bage.referral {
  @extend .status-bage;
  min-width: 120px;
  background-color: #fff6d6;
  color: #d2a813;
}

.status-bage.registration {
  @extend .status-bage;
  min-width: 120px;
  background-color: #e7e7ff;
  color: #7671ff;
}

.status-bage.pending {
  @extend .status-bage;
  background-color: #fff6d6;
  color: #d2a813;
}

.status-bage.failed{
  @extend .status-bage;
  background: #ECECEC;
  color: #747272;
}

.status-bage.processed {
  @extend .status-bage;
  background: #E1E8FF;
  color: #4C6FFF;
}
