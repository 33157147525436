.table-box {
  .padding-adjust {
    padding: 24px 0px;
  }

  .table {
    border: none !important;
    width: 100%;
  }

  .table-div table {
    border: none !important;
  }
  .see-all {
    padding-right: 24px;
  }

  .selecters-row {
    align-items: baseline !important;
    margin-top: 3px;
    .r-search {
      min-width: 232px;
    }

    .r-selects-row {
      margin-left: 60px;
    }
  }
}

.transaction-padding {
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
}

@media screen and (max-width: 1024px) {
  .table-box {
    .selecters-row {
      align-items: baseline !important;
    }
  }
}
