.subscriber-wallet {
  .topbar-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 75px;
    column-gap: .6rem;

    .dropdown{
      position: relative;
      .dtpWidth{
        width: 250px;
        gap: .9rem;
        box-shadow: 0px 0px 2px 0px #32324733;
      }
      .mblWidth{
        width: 110px;
        gap: .3rem;
        .pathIcon{
          width: 21px;
        }
      }

      .top-dropdown {
        height: 52px;
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        .dropdown-img {
          width: 42px;
          height: 42px;
          border-radius: 50%;
          border: 1px solid rgb(213, 213, 213);
        }
      }
  
      .dropdown-val {
        position: absolute;
        top: 65px;
        left: 0px;
        right: 0px;
        background-color: white;
        padding: .4rem .8rem;
        box-shadow: 0px 0px 2px 0px #32324733;
        border-radius: 6px;
        > div {
          display: flex;
          align-items: center;
          border-radius: 6px;
          padding: .5rem 0.4rem;
          cursor: pointer;
          font-size: 14px;
          &:hover {
            background: #fc1b3c;
            color: white;
            box-shadow: 0px 0px 2px 0px #32324733;
          }
        }
      }

    }

    
  }
  .inner-ctn {
    padding: 10px 30px 120px 30px;
    background-color: var(--bg);
    margin: 104px 0px 0px 0px;
  }

  .inner-subscriber {
    margin: 104px 75px 0px 75px;
    padding: 10px 30px 120px 30px;
    background-color: var(--bg);
  }

  .goldie-col {
    margin-top: 32px;
  }

  .col-lg-3:nth-child(13),
  .col-lg-3:nth-child(14) {
    width: 50% !important;
  }
}

.submit-button {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  padding: 16px 24px;
  width: 11rem;
}

.button-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-wallet{
  display: flex;
  gap: 1.8rem;
}

.wallet-tabs {
  color: #718096;
  font-weight: 600;
  padding: 1em 0;
}

.wallet-tabs:hover {
  color: #1a202c;
}

.wallet-tabs.active {
  color: #1a202c;
  border-bottom: 2px solid var(--primary);
}

@media only screen and (max-width: 1124px) {
  .subscriber-wallet {
    .goldie-col {
      width: 33% !important;
    }
  }
  .topbar-content{
    row-gap: .6rem;

    .top-dropdown {
      box-shadow: none;
    }
  }
  
}

@media screen and (max-width: 1024px) {
  .subscriber-wallet {
    .topbar-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 -.8rem 0 0;
      width: 100%;
    }
    .goldie-col {
      width: 24.5% !important;
      margin-top: 29px;
    }
    .inner-subscriber {
      margin: 104px 4px 0px 4px;
    }
  }
}

@media only screen and (max-width: 880px) {
  .subscriber-wallet {
    .goldie-col {
      width: 33% !important;
    }
    .nav-wallet{
      row-gap: .2rem;
      column-gap: .8rem;
    } 
  }
}

@media only screen and (max-width: 640px) {
  .subscriber-wallet {
    .inner-ctn {
      padding: 15px 16px 90px 16px;
    }

    .inner-subscriber {
      margin: 104px 10px 0px 10px;
      padding: 15px 16px 90px 16px;
    }

    .goldie-col {
      width: 49% !important;
      margin-top: 12px;
    }

    .col-lg-3:nth-child(13),
    .col-lg-3:nth-child(14) {
      width: 100% !important;
    }
  }

  .withdrawbutton{
    width: 100%;
  }
  .submit-button {
    width: 100% !important;
    margin: auto !important;
  }

  .button-row {
    margin: auto;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.4rem;
    margin-bottom: 1rem;
  }

  .nav {
    font-size: 12px !important;
  }

  .nav-wallet {
    display: flex;
  }
}

@media only screen and (max-width: 460px) {
  .subscriber-wallet {
    .inner-subscriber {
      margin: 105px 4px 0px 4px !important;
    }
  }
}

@media only screen and (max-width: 420px) {
  .subscriber-wallet {
    .goldie-col {
      width: 100% !important;
    }
    .inner-subscriber {
      margin: 105px 4px 0px 4px !important;
    }
  }
}
