.box-hoc {
  background: #ffffff;
  border-radius: 16px;
  padding: 24px;
  margin-top: 32px;

  box-shadow: 0px 3px 8px -1px rgba(50, 50, 71, 0.05);
  filter: drop-shadow(0px 0px 1px rgba(12, 26, 75, 0.24));
}

@media screen and (max-width: 1024px) {
  .box-hoc {
    padding: 18px;
    margin-top: 24px;
  }
}

@media screen and (max-width: 600px) {
  .box-hoc {
    margin-top: 10px;
    padding: 16 10px;
  }
}
