.change-password-ctn {
  .inner-ctn {
    padding: 30px 30px 120px 30px;
    background-color: var(--bg);
    margin-top: 104px;
  }

  .change-pass-inner {
    .r-form {
      padding-top: 20px;
      display: flex;
      justify-content: space-between;
      margin-bottom: 40px;

      .form-group {
        width: 32%;
      }
    }
    .mediumUpper {
      color: #16192c;
      padding-bottom: 13px;
      border-bottom: 1px solid #edf2f7;
    }
    .low {
      color: #425466;
    }

    .sec-col-div {
      padding: 24px 24px 140px 24px;
      background-color: #f7fafc;
      border-radius: 6px;
    }

    ul {
      li {
        margin-top: 3px;
      }
    }
  }

  .save-btn {
    max-width: 82px;
    margin: auto;
  }
}

@media only screen and (max-width: 590px) {
  .change-pass-inner {
    .r-form {
      flex-wrap: wrap;
      .form-group {
        width: 100% !important;
      }
    }
  }
}

@media only screen and (max-width: 467px) {
  .change-pass-inner {
    .sec-col-div {
      padding-bottom: 40px !important;
    }
  }
}
