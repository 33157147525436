.add-media-company {  
    max-width: 511px;
    .r-form {
      margin-top: 26px;
      .form-group {
        .inner-form {
          .eye-icon-img {
            bottom: 15px;
            right: 14px;
          }
          .field-error {
            bottom: 36px;
          }
        }
      }
    }
  
    .mb-3 {
      margin-bottom: 0.5rem !important;
    }
  
    .r-form label {
      margin-top: 0px !important;
    }
  }
  
  @media only screen and (max-width: 467px) {
    .add-media-company {
      .uploader-box {
        flex-wrap: wrap;
        justify-content: center;
      }
    }
  }
  