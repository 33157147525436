.activity-log {
  .inner-ctn {
    padding: 10px 30px 120px 30px;
    background-color: var(--bg);
    margin-top: 104px;
  }
  table {
    tr {
      th {
        width: 16.6% !important;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .activity-log {
    .inner-ctn {
      padding: 10px 47px 91px 47px;
    }
  }
}

@media screen and (max-width: 700px) {
  .activity-log {
    .inner-ctn {
      padding: 10px 16px 91px 16px;
    }
  }
}
