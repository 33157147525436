.confirmation-popup {
  padding: 30px 0;
  .btn2-style {
    border: 1px solid var(--primary);
    color: var(--primary);
    background-color: #fff;
  }

  .btn2-style.btn:hover {
    color: var(--primary);
    background-color: transparent;
    border-color: var(--primary);
  }

  .single-btn {
    width: 116px;
    margin: auto;
  }

  .popup-top.flex {
    justify-content: center;
  }

  .popup-top {
    .btn-style {
      width: 116px !important;
    }
  }

  .p-text {
    color: #7a828a;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    max-width: 80%;
    margin: auto;
  }
}

@media screen and (max-width: 776px) {
  .confirmation-popup .popup-top .btn-style {
    width: 97px !important;
    margin-right: 10px;
  }
}
