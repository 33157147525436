.trend-art-box {
  background: #ffffff;
  border-radius: 16px;
  padding: 15px;
  box-shadow: 0px 3px 8px -1px rgba(50, 50, 71, 0.05);
  filter: drop-shadow(0px 0px 1px rgba(12, 26, 75, 0.24));
  margin-top: 16px;

  min-height: 102px;
  display: flex;
  .img-tilte {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  .inner-row {
    display: flex;
    flex-direction: column;

    .three-line-text {
      display: -webkit-box;
      max-height: 40px;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .m-title {
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -0.07px;
      color: #27272e;

      // max-width: 255px;
      // overflow: hidden;
      // white-space: nowrap;
      // text-overflow: ellipsis;
    }
  }

  .cmp-img {
    border-radius: 14px;
    width: 72px;
    height: 46px;
  }
}
