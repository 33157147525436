.content {
  padding: 0px !important;
  height: 100vh;
}
.content-admin{
  margin-left: 250px;
}
.content-subscriber{
  margin: 0px !important;
}
.content.is-open {
  margin-left: 0px;
}

@media only screen and (max-width: 1024px) {
  .content {
    margin-left: 0;
  }
}
