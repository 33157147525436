.media-companies {
  width: 100%;
  .media-inner {
    padding: 10px 30px 120px 30px;
    background-color: var(--bg);
    margin-top: 104px;
  }

  .media-cmp-row {
    width: 100%;
  }

  table {
    tr {
      th {
        width: 15% !important;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .media-companies {
    .media-inner {
      padding: 10px 47px 91px 47px;
    }
  }
}

@media screen and (max-width: 700px) {
  .media-companies {
    .media-inner {
      padding: 10px 16px 91px 16px;
    }
  }
}

@media screen and (max-width: 467px) {
  .media-companies {
    button {
      width: 100px !important;
      font-size: 11px !important;
    }
  }
}
