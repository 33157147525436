.user-info {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-left: 2px;

  img {
    border-radius: 50%;
    width: 82px;
    height: 82px;
  }
  .title-info {
    display: flex;
    flex-direction: column;

    span {
      color: #7a7a9d;
    }
  }
}
