.custom-modal {
  text-align: center;

  .modal-content {
    background-color: #fcfafa;
    width: 360px;
    margin: auto;
    padding: 0px;
  }
  .cross-img {
    padding-top: 20px;
    img {
      width: 20px;
      height: 22.863px;
      cursor: pointer;
    }
  }
}

.for-media-popup {
  .modal-content {
    width: 100%;
    padding: 0px 40px 47px 40px;
    background-color: #fcfafa;
  }
}

@media only screen and (max-width: 600px) {
  .custom-modal {
    .modal-content {
      padding: 0px 22px 37px 22px;
      background-color: #fcfafa;
      margin: 6px;
    }

    .cross-img {
      text-align: right;
      padding-top: 14px;

      img {
        width: 19px; 
        height: 19px; 
        margin-right: -6px;
      }
    }
  }
}
