.reset-password-ctn {
  max-width: 700px;
  margin: auto;

  padding: 12% 0;

  .reset-form {
    margin: auto;
    max-width: 400px;
  }

  .reset-note {
    line-height: 22px;
    font-weight: 400;
    font-size: 20px;
    color: #494949;
    text-align: center;
  }

  .img-div {
    text-align: center;
  }
}

@media screen and (max-width: 800px) {
  .reset-password-ctn {
    padding: 50px 30px !important;

    .reset-note {
      font-size: 17px;
    }
  }
}
