.empty-list {
  text-align: center;
  margin: 5%;

  .no-found {
    font-size: 20px;
    font-weight: 400px;
    line-height: 22px;
    color: #494949;
    margin-top: 20px;
  }
}
