.comp-with-subs {
  cursor: pointer;

  .table-img {
    border-radius: 50%;
    width: 36px;
    height: 36px;
  }
  .agency-t {
    font-family: var(--inter);
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 13px;
    color: #7a7a9d;
    cursor: pointer;
  }

  .email {
    max-width: 130px; /* Adjust the max width as needed */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .tooltip {
    border-radius: 0.4rem;
    background-color: #fcfafa;
    color: #8e99a8;
    font-size: 11px;
    font-weight: 500;
    box-shadow: 0px 1px 2px 0px #3232471A;
    padding: 13px;
    line-height: 16px;
  }

  .hash{
    color: #425466;
    font-size: 14px;
  }

  .one-line-txt {
    display: -webkit-box;
    max-height: 40px;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .low-w {
    color: #425466;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 12px;
  }
}
