.settings-container {
  .inner-ctn {
    padding: 10px 30px 120px 30px;
    background-color: var(--bg);
    margin-top: 104px;

    .ref-limit{
      position: relative;
      z-index: 2;
    }

    .ref-reg {
      .r-form {
        .form-group {
          width: 48%;
        }
      }
    }
    .ref-regi {
      .r-form {
        .form-group {
          width: 100%;
        }
      }

      .goldies{
        display: flex;
        align-items: center;
        gap: 2rem;
        .goldiesFields{
          width: 47%;
        }
      }
    }
  }

  .r-form {
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;

    .form-group {
      width: 24%;
    }
  }

  .save-btn {
    max-width: 82px;
    margin: auto;
  }
}

@media screen and (max-width: 1024px) {
  .settings-container {
    .inner-ctn {
      padding: 10px 47px 91px 47px;
    }
  }
}

@media only screen and (max-width: 767px) {
  .settings-container {
    .inner-ctn {
      padding: 10px 16px 91px 16px;
    }
    .r-form {
      flex-wrap: wrap;
      margin-bottom: 50px;

      .form-group {
        width: 49%;
      }
    }
  }
  .settings-container .inner-ctn .ref-regi .goldies{
    flex-direction: column;
    gap: .4rem;

    .conversion{
      display: flex;
      align-items: center;
      justify-content: center;

      >img{
        width: .7rem;
      }
    }
  }
  .settings-container .inner-ctn .ref-regi .goldies .goldiesFields {
    width: 99%;
}
}

@media only screen and (max-width: 467px) {
  .settings-container {
    .r-form {
      flex-wrap: wrap;
      margin-bottom: 20px;

      .form-group {
        width: 99%;
      }
    }
  }
}
