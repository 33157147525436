.btn-style {
  background-color: var(--primary);
  border: none;

  width: 100%;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  color: #ffffff;
  padding: 16px 10px;
}

.btn-style.btn:hover {
  color: #ffffff;
  background-color: var(--hover);
  border-color: var(--hover);
}

.btn-style.btn:disabled,
.btn.disabled,
fieldset:disabled .btn {
  @extend .btn-style;
}

@media screen and (max-width: 600px) {
  .btn-style {
    font-size: 12px;
    padding: 12px 10px;
  }
}
