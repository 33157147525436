.action-btns {
  .action {
    padding: 9px 16px;
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 10px;
    letter-spacing: -0.005em;
    color: #27272e;
    border: 1px solid #edf2f7;
    text-align: center;
    border-radius: 4px;
    cursor: pointer;
  }
  .disable{
    cursor: not-allowed;
    opacity: 25%;
  }
}
