.overlay {
  opacity: 0.6;
  background-color: #f9f5f5;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 9999999999999999;

  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
