.sign-row {
  .cl-1 {
    background-color: #fcfafa;
  }
}
.signIn-img {
  height: 100vh;
  width: 50vw;
}

.signin-col {
  background-color: #fff;

  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.sign-in-box {
  width: 450px;
  margin: auto;
  background: #fcfafa;
  border-radius: 16px;

  box-shadow: 0px 3px 8px -1px rgba(50, 50, 71, 0.05),
    0px 0px 1px 0px rgba(12, 26, 75, 0.24);
  padding: 69px 40px;

  text-align: center;
  height: auto;
  .charc-title {
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 12px;
    color: #7a7a9d;
  }

  .error {
    text-align: center;
  }

  .forgot-pass {
    @extend .charc-title;
    color: var(--primary);
  }

  .m-logo {
    display: none;
  }

  .r-form {
    .eye-icon-img {
      bottom: 40px;
    }
  }

  .back-login {
    color: var(--secondary);
    text-align: center;
    margin-top: 5px;
    cursor: pointer;
  }

  .recovry-title {
    text-align: left;
    .lowMedium {
      color: #27272e;
      span {
        font-weight: 600;
      }
    }
    .email-title {
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      color: #425466;
    }
    .return-txt {
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 12px;
      color: var(--secondary);
      cursor: pointer;
    }
  }
}

@media screen and (max-width: 991px) {
  .cl-1 {
    display: none;
  }

  .col-md-4 {
    width: 100%;
  }

  .sign-in-box {
    .m-logo {
      display: block;
      margin-bottom: 50px;
    }
  }
}

@media screen and (max-width: 762px) {
  .col-md-4 {
    padding: 10%;
  }
}

@media screen and (max-width: 592px) {
  .sign-in-box {
    margin-top: 40px;
    width: 90vw;
    height: 100vh;
  }
  .col-md-4 {
    padding: 3%;
  }
}

@media screen and (max-width: 428px) {
  .sign-in-box {
    .r-form {
      .field-error {
        bottom: 60px;
      }
    }
  }
}
