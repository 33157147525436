.goldies-box {
  background: #ffffff;
  border-radius: 16px;

  padding: 16px 16px 19px 24px;
  box-shadow: 0px 3px 8px -1px rgba(50, 50, 71, 0.05);
  filter: drop-shadow(0px 0px 1px rgba(12, 26, 75, 0.24));

  min-height: 144px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .goldies-row {
    img {
      border-radius: 50%;
      width: 46px;
      height: 46px;
    }

    .sixty-twelve {
      color: #7a7a9d;
    }
  }
  .goldies-count {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 136.52%;
    color: #16192c;
    padding-top: 4px;
  }

  .subs-count {
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 16px;
    color: #7a7a9d;
  }
}

@media screen and (max-width: 1024px) {
  .goldies-box {
    padding: 16px;
  }
}
