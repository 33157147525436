.world-map-ctn {
  width: 100%;

  overflow: hidden;

  flex-direction: column;
  .highlight-area {
    position: relative;
    cursor: pointer;
  }

  .leg-title {
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    color: #7a7a9d;
  }

  .circle {
    background: #4c6fff;
    width: 8px;
    height: 8px;
    border-radius: 50%;
  }

  .circle-1 {
    @extend .circle;
    background-color: #ff92ae;
  }
  .viv {
    display: block;
    transform: scale(1);
  }
  .tool-text-box {
    position: absolute;
    bottom: 75px;
    left: 170px;
    background: linear-gradient(
      180deg,
      #f3f4fe 0%,
      rgba(243, 244, 254, 0) 100%
    );
    backdrop-filter: blur(5px);
    border-radius: 7px;
    padding: 14px 12px;

    transition: 1300ms ease-in-out;

    .country-name {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 162.02%;
      text-align: center;
      font-feature-settings: "salt" on;
      color: #4c6fff;
    }
  }

  .agency-tool-box {
    background: linear-gradient(
      180deg,
      #fff3f6 0%,
      rgba(255, 243, 246, 0) 100%
    );
  }
}

@media only screen and (max-width: 1630px) {
  .world-map-ctn {
    svg {
      margin-top: 20px;
      height: 100vh;
      max-height: 400px;
      width: 100%;
    }
  }
}

@media only screen and (max-width: 1430px) {
  .world-map-ctn {
    svg {
      height: 315px;
    }
  }
}

@media only screen and (max-width: 700px) {
  .world-map-ctn {
    svg {
      height: auto !important;
    }
  }
}
