.cmp-img-in {
  border-radius: 50%;
  width: 36px;
  height: 36px;
  min-width: 36px;
}

.default-v-a-pl {
  border-radius: 14px;
  width: 72px;
  height: 46px;
}
